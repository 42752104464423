import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay,} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Fade from "react-reveal/Fade";
import SectionWrapper, { Container } from "./googleReviews.style";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);



const GoogleReviews = () => {
  const allGoogleReviews = [
    {
      title: "Mrinal Mehta",
      firstlatter: "M",
      description:
        "I would highly recommend them. Their personnel are incredibly helpful and compassionate, and they provide fantastic services. They are the greatest at what they do",
      date: "2 months ago",
    },
    {
      title: "Adarsh Shukla",
      firstlatter: "A",
      description:
        "I thanked the staff. A positive experience. The service was provided correctly, and the staff was courteous.Cost was bit high. I will highly recommend it. ",
      date: "a week ago",
    },
    {
      title: "Fahad Asif",
      firstlatter: "F",
      description:
        "If you wish to regain your previous look, Radiance is the ideal destination. The staff is incredibly friendly, the atmosphere is fantastic, and you are guaranteed not to be disappointed. so please go there if u wanna feel the change and be young again.",
      date: "a month ago",
    },
    {
      title: "Praveen",
      firstlatter: "P",
      description:
        "I recently had the pleasure of getting a customized hair hair wig for ladies in Delhi, and I'm really pleased with the outcome.The end product was just perfect, and the procedure went smoothly. The wig was nearly indistinguishable from my genuine hair because it precisely matched the colour and texture of my natural hair. It's really cosy to wear, which enables me to easily try out various styles. This is the place to go if you're in Delhi and want a premium customised wig. ",
      date: "2 weeks ago",
    },
    {
      title: "Prem Pandey",
      firstlatter: "P",
      description:
        "Hello, I started using hair patches on March 22.out of Radiance. The patch scared me at first, but I grew accustomed to it with time.The technician was helpful, especially Deepak, who was patient enough to change my desired hairstyle. helpful and thanks to her. It is cost effective for the patch except the monthly maintenance cost though.",
      date: "2 month ago",
    },
    {
      title: "Sunil Rastogi",
      firstlatter: "S",
      description:
        "I had an amazing experience at Radiance Hair Studio in Delhi! Their hair wigs are incredibly pleasant to wear, of outstanding quality, and appear entirely natural. The staff provides excellent advise and tailored solutions since they are competent and genuinely understand the demands of their clients. I heartily recommend Radiance to everyone in Delhi searching for high-quality wigs!",
      date: "2 month ago",
    },
    {
      title: "Aarav Gupta",
      firstlatter: "A",
      description:
        "I had an amazing experience at Radiance Hair Studio in Delhi! Their hair wigs look incredibly natural and are of exceptional quality. In order to help me select the ideal wig that fit my preferences and style, the staff was really helpful. Highly recommend their services!",
      date: "2 weeks ago",
    },
    {
      title: "Shasan Ali",
      firstlatter: "S",
      description:
        "I got a hair patch from these guys. They're incredible. I am very happy with there services.",
      date: "2 month ago",
    },
    {
      title: "Riya Singh",
      firstlatter: "R",
      description:
        "Radiance Hair Studio is where I obtained my hair wig, and I love how it looks! The wig immediately increased my confidence because it looks so natural. I heartily suggest it to anyone seeking professionalism and excellence!",
      date: "a week ago",
    },
    {
      title: "Sneha Mandal",
      firstlatter: "S",
      description:
        "Friendly and Good behaving Staff. Experienced Hair Stylists. Quality Products Worth for money.Overall Had a Great Experience.",
      date: "a month ago",
    },
    {
      title: "Arjun Mehta",
      firstlatter: "A",
      description:
        "I visited Radiance Hair Studio for a hair wig consultation, and I couldn’t be happier with the results! The wig I received is soft and lightweight, and it blends in flawlessly with my natural hair. They took the time to make the wig fit me precisely, and the service was professional. Best place in Delhi for hair wigs!",
      date: "2 weeks ago",
    },
    {
      title: "Aditya Reddy",
      firstlatter: "A",
      description:
        "My experience at Radiance Hair Studio was fantastic! My appearance has been totally changed by the hair extensions, which look quite natural. Throughout the process, the personnel made sure I was comfortable and was very professional. I heartily suggest it to everyone in Delhi searching for high-quality hair extensions!",
      date: "3 month ago",
    },
    {
      title: "The Kaida",
      firstlatter: "T",
      description:
        "I Would Highly Recommend Them.They are a very caring and wonderful staff..They are the best...See You Soon Thanks Again.",
      date: "a month ago",
    },
    {
      title: "Shivam Sharma",
      firstlatter: "S",
      description:
        "I frequent Radiance Hair Studio on a daily basis. Mr. Deepak is a really kind and encouraging man who always makes sure to meet with me to help me choose my hairstyle. my requirements.",
      date: "2 month ago",
    },
    {
      title: "Kavya Choudhary",
      firstlatter: "K",
      description:
        "Radiance Hair Studio is the best for hair bonding! My hair has never looked better. I looked natural and full because to the bonding method they employed, and the service was first-rate. Super happy with the results!",
      date: "2 weeks ago",
    },
    {
      title: "Meera Nair",
      firstlatter: "/M",
      description:
        "My hair weaving experience at Radiance Hair Studio was fantastic! The outcomes are better than I could have imagined, and the staff is very competent.My hair looks so natural, and I feel more confident than ever. I heartily recommend these to everyone who wants to bring back the volume and beauty of their hair!",
      date: "a week ago",
    },
  ];

  return (
    <SectionWrapper id="googleReviews">
      <Container className="myContainer">
        <div className="row">
          <div className="col-md-4">
          <Fade top>
              <img
                className="greviewimg showmob"
                width="404"
                height="434"
                src="/service-images/Reviewss.jpg"
                alt="google reviews"
                style={{ marginTop: "10px" }}
              />
            </Fade>
            <Fade left>
              <img
                className="greviewimg hidemob"
                width="404"
                height="434"
                src="/home/review.webp"
                alt="google reviews"
                style={{ marginTop: "10px" }}
              />
            </Fade>
          </div>
          <div className="col-md-8">
            <div
              className="reviewFold"
              id="googleReviews"
              style={{ display: "block" }}
            >
              <div className="flex">
                <Fade left>
                  <div className="leftGarea">
                    <h3 className="title">Highest Number of Google Rating in Delhi-NCR in This Segment</h3>
                    <p className="subheadstar">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <span>4.9 Rating of 2000+ Reviews</span>
                    </p>
                  </div>
                </Fade>
                <Fade right>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJc5hFOhUDDTkR84Qd5N0PQ58"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-default btn-lg black"
                    style={{ marginLeft: "auto" }}
                  >
                    Write a Review
                  </a>
                </Fade>
              </div>
              <div className="divider" style={{ margin: "15px auto" }}></div>
              <Fade bottom>
                <div
                  className="swiperOverfloww"
                  style={{ marginBottom: "60px" }}
                >
                  <div className="flex gReviewsIcos">
                    {/* Custom navigation buttons */}
                    <div className="swiper-button-prevG">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="swiper-button-nextG">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                  <Swiper
                    spaceBetween={30} // Adjust as needed
                    slidesPerView={2} // Display 4 slides per view
                    // autoplay={{ delay: 3000 }}
                    navigation={{
                      prevEl: ".swiper-button-prevG",
                      nextEl: ".swiper-button-nextG",
                    }}
                    // pagination={{ clickable: true }}
                    breakpoints={{
                      300: {
                        // width: 768,
                        slidesPerView: 1,
                      },
                      768: {
                        // width: 768,
                        slidesPerView: 2,
                      },
                    }}
                  >
                    {allGoogleReviews.map((review, index) => (
                      <SwiperSlide key={index}>
                        <div className="gCard">
                          <div className="gHead">
                          <p className="mb-0"> {review.firstlatter}</p>
                            {/* <img
                              loading="lazy"
                              width="101"
                              height="101"
                              src={review.image}
                              alt={`Reviewimg for ${review.title}`}
                            /> */}
                          </div>
                          <p className="reviewPara" id="scrollable">
                            <img
                              loading="lazy"
                              width="111"
                              height="111"
                              src="/home/quote.svg"
                              alt={`Reviewimg for ${review.title}`}
                            />
                            {review.description}
                          </p>
                          <div className="gFooter">
                            <div className="gHeadContent">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <h5>{review.title}</h5>
                              <p>{review.date}</p>
                            </div>
                            <img
                              width="68"
                              height="68"
                              src="/google.png"
                              alt="Review 1"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        
      </Container>
    </SectionWrapper>
  );
};

export default GoogleReviews;
